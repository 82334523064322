import React from "react";
import "./HomeFooter.css";
import icon1 from "../assets/image/footer_facebook.svg";
import icon2 from "../assets/image/footer_insta.svg";
import icon3 from "../assets/image/footer_linkedin.svg";
import icon4 from "../assets/image/footer_twitter.svg";
import icon5 from "../assets/image/footer_youtube.svg";

const HomeFooter = () => {
  return (
    <div className="footer_1">
      <div className="Footer_Head">
        <div className="Footer1">
          <h1>For Demo Call 82772 82772</h1>
        </div>
      </div>
      <div className="Footer_Links">
        <div className="Footer_components">
          <div className="Our_Sites">
            <p>Our websites</p>
            <a
              href="https://safeprocctv.com/"
              target="_blank"
              className="Site_Button"
              rel="noreferrer"
            >
              safeprocctv.com
            </a>
            <a
              style={{ borderRight: "none" }}
              href="https://www.imtechready.com/"
              target="_blank"
              className="Site_Button"
              rel="noreferrer"
            >
              imtechready.com
            </a>
            {/* <a
              href="https://www.codingeni.com/"
              target="_blank"
              className="Site_Button1"
              rel="noreferrer"
            >
              codingeni.com
            </a> */}
          </div>
        </div>
      </div>
      <div className="products">
        <div className="Products_Link_Section">
          <div className="ProductLinks">
            <div className="Products_1">
              <a href="/smartcity/octasense.html">Octasense</a>
              <br />
              <a href="/smartcity/vigilar.html">Vigilar </a>
              <br />
              <a href="/facesense.html">Facesense </a>
              <br />
              <a href="/cctv/retailstores/people-counting.html">Retailytics</a>
              <br />
              <a href="/anpr.html">ANPR Camera </a>
            </div>
            <div className="Products_2">
              <a href="/Video-analytics">Video Analytics </a>
              <br />
              <a href="/cctv/perimetrion.html">Perimetrion</a>
              <br />
              <a href="/face-recognition-time-attendance-System.php">
                Time & Attendance System
              </a>
              <br />
              <a href="/ezparking">Ezparking </a>
              <br />
              <a href="/videoanalytics/ppedetection/pharma.html">
                Safety gear violation
              </a>
            </div>
            <div className="Products_3">
              <a href="/edumetrics">Edumetrics </a>
              <br />
              <a href="/Custom-Made-Video-Analytics">Custom Made Analytics </a>
              <br />
              <a href="/admetrics">Admetrics</a>
              <br />
              <a href="/videoanalytics/object-detection.html">
                Object Detection & Counting
              </a>
              <br />
              <a href="/temperature-tracking-camera/covid-19/thermography.html">
                Thermography
              </a>
            </div>
          </div>
          <div className="Reachus">
            <div className="Work_Hours">
              <h2>
                BUSINESS HOURS{" "}
                <span>(Mon&nbsp;-&nbsp;Fri.&nbsp;10AM&nbsp;-&nbsp;6PM)</span>
              </h2>
            </div>
            <div className="Contact">
              <a href="tel:82772 82772">
                <div className="text">
                  <label>Mobile</label>
                  <label>:</label>
                  <label>8660086363 / 8277282772</label>
                </div>
              </a>
              <a href="tel:80 2323 0607">
                <div className="text">
                  <label>Landline</label>
                  <label>:</label>
                  <label>80 2323 0607</label>
                </div>
              </a>
              {/* <a href="tel:82771 01111">
                <div className="text">
                  <label>Cust. Support</label>
                  <label>:</label>
                  <label>82771 01111</label>
                </div>
              </a> */}
              <a href="mailto:reachus@safepro.tech">
                <div className="text">
                  <label>Support</label>
                  <label>:</label>
                  <label>reachus@safepro.tech</label>
                </div>
              </a>
            </div>
            <div className="Social">
              <p>
                <a
                  href="https://www.linkedin.com/company/safepro-video-security-research-lab"
                  target="_blank"
                  rel="noreferrer"
                  aria-label="Visit SafePro's LinkedIn page"
                >
                  <img srcSet={icon3} alt="LinkedIn logo for SafePro" />
                </a>
              </p>
              <p>
                <a
                  href="https://www.instagram.com/safepro.ai/"
                  target="_blank"
                  rel="noreferrer"
                  aria-label="Visit SafePro's Instagram page"
                >
                  <img srcSet={icon2} alt="Instagram logo for SafePro" />
                </a>
              </p>
              <p>
                <a
                  href="https://twitter.com/Safepro_tech"
                  rel="noreferrer"
                  target="_blank"
                  aria-label="Visit SafePro's Twitter page"
                >
                  <img srcSet={icon4} alt="Twitter logo for SafePro" />
                </a>
              </p>
              <p>
                <a
                  href="https://www.facebook.com/safeprovideo"
                  target="_blank"
                  rel="noreferrer"
                  aria-label="Visit SafePro's Facebook page"
                >
                  <img srcSet={icon1} alt="Facebook logo for SafePro" />
                </a>
              </p>
              <p>
                <a
                  href="https://www.youtube.com/@safeproaivideoresearchlabs"
                  target="_blank"
                  rel="noreferrer"
                  aria-label="Visit SafePro's YouTube channel"
                >
                  <img srcSet={icon5} alt="YouTube logo for SafePro" />
                </a>
              </p>
            </div>
          </div>
        </div>
        <p className="Copyright">
          Copyright © 2025 Safepro AI Video Research Labs Pvt. Ltd.
        </p>
      </div>
    </div>
  );
};

export default HomeFooter;
